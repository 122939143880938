.label {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  position: relative;
}

.input {
  width: 100%;
  padding: 12px 0;
  border: 0;
  border-bottom: 1px solid rgb(0, 0, 0, 0.3);
  font-size: 16px;
  line-height: 1.2;
  outline: none;
  transition: 0.3s;
  box-sizing: border-box;
  background-color: #fcfcfc;
}

.inputError {
  border-color: #ff0000;
}

.error {
  font-size: 12px;
  line-height: 1.25;
  color: #ff0000;
  position: absolute;
  top: calc(100%);
  left: 0;
  opacity: 0;
  transition: 0.3s;
}

.errorActive {
  opacity: 1;
}

.placeholder {
  font-size: 16px;
  line-height: 1.2;
  color: rgb(0, 0, 0, 0.3);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) scale(1);
  transition: 0.3s;
  pointer-events: none;
  padding: 4px;
  background-color: #fcfcfc;
}

.input:focus + .placeholder,
.placeholderFixed {
  font-size: 14px;
  transform: translateY(-180%) scale(1);
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px #fcfcfc inset !important;
 transition: background-color 5000s ease-in-out 0s;
}
