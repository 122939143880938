.fields {
  border: 0;
  padding: 0;
  margin: 24px 0 0 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.label {
  width: 30%;
  position: relative;
  margin-left: 20px;
}

.select {
  height: 100%;
  border-radius: 8px;
  min-height: 45px;
}

.input {
  width: 100%;
  padding: 12px 0;
  border: 0;
  border: 1px solid rgb(0, 0, 0, 0.3);
  font-size: 16px;
  line-height: 1.2;
  outline: none;
  transition: 0.3s;
  box-sizing: border-box;
  padding-left: 8px;
  border-radius: 8px;
}

@media screen and (max-width: 500px) {
  .fields {
    flex-wrap: wrap;
    margin-top: 40px;
  }
  .bankLabel {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .select {
    width: 65%;
  }
  .label:nth-of-type(2) {
    margin-left: 0px;
  }
}
