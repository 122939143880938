.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

.linksItem {
  display: inline;
  margin-left: 16px;
}

.link {
  text-decoration: none;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.33;
  padding: 10px;
  color: #b6bcbf;
  position: relative;
  transition: 0.4s;
}

.linkActive {
  color: #1a1b22;
}

.linkActive::after {
  content: "";
  width: 80%;
  height: 3px;
  background-color: #1a1b22;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.button {
  padding: 11px 17px;
  display: inline-flex;
}

.buttonLogged {
  border: 1px solid #b6bcbf;
  align-items: center;
}

.buttonLogged::after {
  content: "";
  background-image: url(../../images/navigation-logout.svg);
  width: 24px;
  height: 24px;
  margin-left: 6px;
}

@media (hover: hover) and (pointer: fine) {
  .link:hover {
    opacity: 0.7;
  }
  .buttonLogged:hover {
    border: 1px solid #fff;
  }
  .linkActive::after:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 500px) {
  .button {
    padding: 4px 8px;
  }
  .linksItem {
    margin-left: 0px;
  }
}
