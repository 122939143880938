.header {
  padding: 15px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.logo {
  width: 200px;
  height: 51px;
}

@media screen and (max-width: 950px) {
  .header {
    padding: 12px 20px;
  }
}
