.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 104px;
  padding-right: 104px;
}

.initial {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle {
  display: block;
  width: 50px;
  height: 50px;
  border: 3px solid #b6bcbf;
  border-bottom-color: #d1d2d6;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
  margin-bottom: 16px;
}

.preloaderButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.initialButton {
  margin: auto;
}

.circleButton {
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #b6bcbf;
  border-bottom-color: #d1d2d6;
  border-radius: 50%;
  animation: spin 0.75s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 650px) {
  .preloader {
    padding-left: 16px;
    padding-right: 16px;
  }
}
