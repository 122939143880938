.button {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.33;
  box-sizing: border-box;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  transition: 0.4s;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 5px 15px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.button:focus {
  outline: none;
}

.button:disabled {
  cursor: default;
}

.button:disabled:hover {
  background-color: transparent;
}

@media screen and (max-width: 500px) {
  .button {
    font-size: 14px;
  }
}
