.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px auto 0;
  width: 70%;
  max-width: 900px;
}

.title {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 1.25;
  text-align: center;
  color: #2e2e2e;
  margin: 24px 0 0 0;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 950px) {
  .title {
    font-size: 24px;
  }
  .section {
    width: 90%;
  }
}
