@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

.page {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  min-width: 320px;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin: 0 auto;
  background-color: #fcfcfc;
  color: #1a1b22;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
