.main {
  min-height: 72vh;
}

/* .main::before {
  content: '';
  background-image: url(../../images/main.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -20;
  opacity: .7;
} */
