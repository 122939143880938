.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  position: relative;
}

@media screen and (max-width: 950px) {
  .form {
    margin-top: 20px;
  }
}
