.form {
  max-width: 400px;
  padding-top: 60px;
}

.fields {
  border: 0;
  padding: 0;
  margin: 0 0 120px;
}

.label {
  margin-bottom: 40px;
}

.button {
  align-self: center;
  min-width: 120px;
}

.text {
  font-size: 14px;
  line-height: 1.43;
  color: #000;
  align-self: center;
  margin-top: 16px;
  margin-bottom: 0;
}

.link {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.43;
  color: #2f71e5;
}

.submitError {
  width: 100%;
  font-size: 14px;
  line-height: 1.25;
  color: #ff0000;
  transition: 0.3s;
  margin-top: 12px;
  opacity: 0;
  text-align: center;
}

.submitErrorActive {
  opacity: 1;
}

@media screen and (max-width: 950px) {
  .form {
    padding: 40px 30px;
    max-width: 350px;
  }
  .fields {
    margin-bottom: 40px;
  }
}
