.fields {
  border: 0;
  padding: 0;
  margin: 0;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 50px;
}

.input {
  border: 1px solid rgb(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 20px;
}

.inputError {
  border-color: #ff0000;
}

.placeholder {
  margin-left: 15px;
}

.button {
  align-self: center;
  min-width: 120px;
}

.text {
  font-size: 14px;
  line-height: 1.43;
  color: #000;
  align-self: center;
  margin-top: 16px;
  margin-bottom: 0;
}

.link {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.43;
  color: #2f71e5;
}

.submitError {
  width: 100%;
  font-size: 14px;
  line-height: 1.25;
  color: #ff0000;
  transition: 0.3s;
  margin-top: 12px;
  opacity: 0;
  text-align: center;
}

.submitErrorActive {
  opacity: 1;
}

@media screen and (max-width: 500px) {
  .fields {
    margin-top: 32px;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
}
